import React, { Component } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import 'react-toastify/dist/ReactToastify.css';
import Styles from './index.module.css';
import { updatePassword } from '@reducers/payment';
import { ToastContainer, toast } from 'react-toastify';
import { el } from 'date-fns/locale';
class ChangePassword extends Component {
  constructor(props) {
    super(props);
    this.state = {
        oldPassword: "",
        newPassword: "",
        confirmPassword: "",
        oldPasswordError: "",
        passwordError: "",
        confirmPasswordError: "",
    };
  }

  componentDidMount = async () => {
  };
  
   // Handle input changes and reset errors for the changed field
   handleChange = (e) => {
    const { name, value } = e.target;

    // Reset specific error based on which field is being changed
    this.setState({
      [name]: value,
      oldPasswordError: name === "oldPassword" ? "" : this.state.oldPasswordError,
      passwordError: name === "newPassword" ? "" : this.state.passwordError,
      confirmPasswordError: name === "confirmPassword" ? "" : this.state.confirmPasswordError,
    });
  };

  // Validate form fields
  validateForm = () => {
    let oldPasswordError = "";
    let passwordError = "";
    let confirmPasswordError = "";

    // Validate oldPassword
    const emailRegex = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,6}$/;
    if (!this.state.oldPassword) {
      oldPasswordError = "Please enter your current password";
    }

   // Validate password with the new criteria
   const passwordRegex = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/;
   if (!this.state.newPassword || !passwordRegex.test(this.state.newPassword)) {
     passwordError = "Password must contain at least 8 characters, one uppercase, one lowercase, one number, and one special character.";
   }

    // Validate confirm password
    if (this.state.newPassword !== this.state.confirmPassword) {
      confirmPasswordError = "Passwords do not match";
    }

    // Set the errors in state
    this.setState({
      oldPasswordError,
      passwordError,
      confirmPasswordError,
    });

    // Return true if no errors
    return !oldPasswordError && !passwordError && !confirmPasswordError;
  };

  // Handle form submit
  handleSubmit = async (e) => {
    e.preventDefault();
    
    const { oldPassword, newPassword, confirmPassword } = this.state;
    const currentPassword = oldPassword;
    // Validate form before submitting
    if (this.validateForm()) {
      try {
        await this.props.updatePassword({ currentPassword, newPassword, confirmPassword });
        if(this.props.passwordChangeResponse && this.props.passwordChangeResponse.status === "success"){
          alert("Password changed successfully");
          this.props.history.push('/dashboard');
        }
      } catch (error) {
        toast.error("Error changing password :"+ error.data.message);
      }
    }
    else{
      console.log("Form has errors");
    }
    // Validate form before submitting
    // if (this.validateForm()) {
    //   // Form is valid, proceed with submission logic
    //   console.log("Form submitted successfully!");
    //   let data = {};
    //   data.currentPassword = this.state.oldPassword;
    //   data.newPassword = this.state.newPassword;
    //   data.confirmationPassword = this.state.confirmPassword;
    //   console.log(data)
    //   this.props.updatePassword(data);
    // } else {
    //   console.log("Form has errors");
    // }
  };

  render() {
    return (
      <div className={Styles['reset-password-form']}>
        <ToastContainer position="bottom-right" />
        <h2>Change Password</h2>
        <form onSubmit={this.handleSubmit}>
          <div className={Styles['form-group']}>
            <label htmlFor="oldPassword">Current Password</label>
            <input
              type="password"
              id="oldPassword"
              name="oldPassword"
              value={this.state.oldPassword}
              onChange={this.handleChange}
              placeholder="Enter your current password"
            />
            {this.state.oldPasswordError && <span className={Styles['error']}>{this.state.oldPasswordError}</span>}
          </div>

          <div className={Styles['form-group']}>
            <label htmlFor="newPassword">New Password</label>
            <input
              type="password"
              id="newPassword"
              name="newPassword"
              value={this.state.newPassword}
              onChange={this.handleChange}
              placeholder="Enter new password"
            />
            {this.state.passwordError && <span className={Styles['error']}>{this.state.passwordError}</span>}
          </div>

          <div className={Styles['form-group']}>
            <label htmlFor="confirmPassword">Confirm Password</label>
            <input
              type="password"
              id="confirmPassword"
              name="confirmPassword"
              value={this.state.confirmPassword}
              onChange={this.handleChange}
              placeholder="Confirm your new password"
            />
            {this.state.confirmPasswordError && <span className={Styles['error']}>{this.state.confirmPasswordError}</span>}
          </div>

          <button type="submit">Reset Password</button>
        </form>
      </div>
    );
  }
}

const mapStateToProps = state => ({
  passwordChangeResponse: state.payment.passwordChangeResponse
});

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      updatePassword
    },
    dispatch
  );

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(ChangePassword)
);
